"use strict";

/**
 * Created by Thiago Lima on 20/02/2020.
 */

(function () {
  'use strict';

  function dependencies(window) {
    return {
      aggregateMetricsLanguage: window.aggregateMetricsLanguage,
      languageContainerLanguage: window.languageContainerLanguage,
      contextCardLanguage: window.contextCardLanguage,
      groupsContainerLanguage: window.groupsContainerLanguage,
      themesContainerLanguage: window.themesContainerLanguage,
      generalLanguage: window.generalLanguage,
      hashtagsInstagramBusiness: window.hashtagsInstagramBusiness,
      suggestionContainerLanguage: window.suggestionContainerLanguage,
      nameContainerLanguage: window.nameContainerLanguage,
      panelSettingsGeneralLanguage: window.panelSettingsGeneralLanguage
    };
  }
  var _dependencies = dependencies(window),
    aggregateMetricsLanguage = _dependencies.aggregateMetricsLanguage,
    languageContainerLanguage = _dependencies.languageContainerLanguage,
    contextCardLanguage = _dependencies.contextCardLanguage,
    groupsContainerLanguage = _dependencies.groupsContainerLanguage,
    themesContainerLanguage = _dependencies.themesContainerLanguage,
    hashtagsInstagramBusiness = _dependencies.hashtagsInstagramBusiness,
    generalLanguage = _dependencies.generalLanguage,
    suggestionContainerLanguage = _dependencies.suggestionContainerLanguage,
    nameContainerLanguage = _dependencies.nameContainerLanguage,
    panelSettingsGeneralLanguage = _dependencies.panelSettingsGeneralLanguage;
  var pt = {
    aggregateMetricsLanguage: aggregateMetricsLanguage,
    languageContainerLanguage: languageContainerLanguage,
    contextCardLanguage: contextCardLanguage,
    groupsContainerLanguage: groupsContainerLanguage,
    themesContainerLanguage: themesContainerLanguage,
    hashtagsInstagramBusiness: hashtagsInstagramBusiness,
    generalLanguage: generalLanguage,
    suggestionContainerLanguage: suggestionContainerLanguage,
    nameContainerLanguage: nameContainerLanguage,
    panelSettingsGeneralLanguage: panelSettingsGeneralLanguage
  };
  console.log(pt);
  window.moduleExport({
    pt: pt
  }, typeof module !== 'undefined' && module);
})();